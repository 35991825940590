<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- 구분 -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="year"
            default="today"
            label="년도"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="공휴일 목록"
      rowKey="holiday"
      selection="multiple"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" :showLoading="false" icon="add" @btnClicked="add" />
          <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="false" :editable="editable" icon="remove" @btnClicked="removeItem" />
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="insertUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveItem"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'holiday',
  data() {
    return {
      searchParam: {
        year: '',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'holiday',
            field: 'holiday',
            label: '공휴일',
            type: 'date',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'memo',
            field: 'memo',
            label: '메모',
            align: 'left',
            sortable: true,
            style: 'width:600px',
            type: 'text',
          },
        ],
        data: [],
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSave: false,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.holiday.list.url;
      this.insertUrl = transactionConfig.sys.holiday.insert.url;
      this.updateUrl = transactionConfig.sys.holiday.update.url;
      this.deleteUrl = transactionConfig.sys.holiday.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        holiday: '',
        memo: '',
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let holidayArr = this.$_.map(this.grid.data, 'holiday');
        let duppleRst = [...new Set(holidayArr.filter((item, index) => holidayArr.indexOf(item) !== index))]
        if (duppleRst && duppleRst.length > 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '중복되는 공휴일이 존재합니다.<br>['
                      + duppleRst.join(', ') + ']',
            type: 'warning', // success / info / warning / error
          });
          return;
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    }, 
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { holiday: item.holiday })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
